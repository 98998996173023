// 组织
import { tongke } from '@/axios'
class Organize {
  list() {
    return tongke.get('/l/organization')
  }
  /**
   * @functionName  create
   * @param { Object }  params
   * @param { Number }  params.pid  上级ID
   * @param { String }  params.name 组织名称
   * @param { Number }  params.grade_id 组织类型
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @param { String }  params.sort 排序值
   * @param { String }  params.description  描述
   * @Description
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/organization', params)
  }
  /**
   * @functionName  update
   * @param { Object }  params
   * @param { Number }  params.id  组织ID
   * @param { Number }  params.pid  上级ID
   * @param { String }  params.name 组织名称
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @param { String }  params.sort 排序值
   * @param { String }  params.description  描述
   * @Description
   * @return { Object }
   */
  update(params) {
    return tongke.put(`/l/organization/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/organization/${id}`)
  }
}

export default new Organize()
