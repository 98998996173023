// 规则
import { tongke } from '@/axios'
class Rule {
  /**
   * @functionName list
   * @Description 规则列表
   * @return { Object }
   */
  list() {
    return tongke.get('/l/rule')
  }
  /**
   * @functionName  create
   * @param { Object }  params.pid  上级id
   * @param { Object }  params.name 规则名称 中文
   * @param { Object }  params.type 规则类型 page、api
   * @param { Object }  params.method 接口请求方式 GET、POST、PUT、DELETE
   * @param { Object }  params.operation  验证接口标识 index、read、create、update、delete
   * @param { Object }  params.service_router 接口路由
   * @param { Object }  params.client_router  客户端路由
   * @param { Object }  params.client_route_name  客户端路由名称
   * @param { Object }  params.client_route_alias 客户端路由别名
   * @param { Object }  params.icon 图标
   * @param { Object }  params.sort 排序值
   * @Description 创建规则
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/rule', params)
  }
  /**
   * @functionName  update
   * @param { Object }  params.id  规则id
   *
   * @param { Object }  params.pid  上级id
   * @param { Object }  params.name 规则名称 中文
   * @param { Object }  params.type 规则类型 page、api
   * @param { Object }  params.method 接口请求方式 GET、POST、PUT、DELETE
   * @param { Object }  params.operation  验证接口标识 index、read、create、update、delete
   * @param { Object }  params.service_router 接口路由
   * @param { Object }  params.client_router  客户端路由
   * @param { Object }  params.client_route_name  客户端路由名称
   * @param { Object }  params.client_route_alias 客户端路由别名
   * @param { Object }  params.icon 图标
   * @param { Object }  params.sort 排序值
   * @Description 更新规则
   * @return { Object }
   */
  update(params) {
    return tongke.put(`/l/rule/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/rule/${id}`)
  }
}

export default new Rule()
