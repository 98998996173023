// 角色
import { tongke } from '@/axios'
class Role {
  /**
   * @functionName list
   * @param { Object } params
   * @param { Number } params.org_id 组织ID
   * @Description 角色列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/l/role', { params })
  }
  /**
   * @functionName  create
   * @param { Object }  params
   * @param { Number }  params.pid  上级ID
   * @param { Number }  params.org_id 组织ID，不传或传0为本单位创建
   * @param { String }  params.name 角色名称
   * @param { Number }  params.status 1-启用|2-禁用
   * @param { String }  params.remark 描述
   * @param { Array<Number> }  params.rules ---规则权限id
   * @Description 创建角色
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/role', params)
  }
  /**
   * @functionName  update
   * @param { Object }  params
   * @param { Number }  params.id   角色ID
   * @param { Number }  params.org_id 组织ID，不传或传0为本单位创建
   * @param { String }  params.name 角色名称
   * @param { Number }  params.status 1-启用|2-禁用
   * @param { String }  params.remark 描述
   * @param { Array<Number> }  params.rules ---规则权限id
   * @Description 更新角色
   * @return { Object }
   */
  update(params) {
    return tongke.put(`/l/role/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/role/${id}`)
  }
}

export default new Role()
