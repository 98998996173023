<template>
  <el-dialog
    append-to-body
    :title="title"
    ref="dialog"
    width="500px"
    :visible.sync="show"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :before-close="reset"
  >
    <el-form ref="form" :model="form.data" label-width="100px" :rules="form.rules">
      <el-form-item label="上级机构" prop='pid'>
        <el-cascader
          v-model="form.data.pid"
          :options="OrgTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择上级机构"
          clearable
          size="small"
          :showAllLevels="false"
          :disabled="type==='update'"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="单位名称：" prop="name">
        <el-input v-model="form.data.name" placeholder="请选择单位名称" clearable maxlength="100" size="small"></el-input>
      </el-form-item>
      <el-form-item label="单位级别：" prop="grade_id">
        <el-cascader
          v-model="form.data.grade_id"
          :options="OrgGradeTree"
          :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
          placeholder="请选择单位级别"
          clearable
          size="small"
          :showAllLevels="false"
          :disabled="type==='update'"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="所在地：" class="location">
        <v-distpicker
          @province="province"
          @city="city"
          @area="area"
          :province="form.data.province"
          v-if="type==='create'"
        ></v-distpicker>
        <v-distpicker
          @province="province"
          @city="city"
          @area="area"
          :province="form.data.province"
          :city="form.data.city"
          :area="form.data.county"
          v-if="type==='update'"
        ></v-distpicker>
        <div class="location_error" v-if="location_error">{{location_error}}</div>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input v-model="form.data.contact_name" placeholder="请输入联系人" clearable maxlength="100" size="small"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input
          v-model.number="form.data.contact_tel"
          type="number"
          placeholder="请输入联系电话"
          clearable
          maxlength="100"
          size="small"
        ></el-input>
      </el-form-item>
      <div class="submit">
        <el-button size="small" @click="reset">取消</el-button>
        <el-button type="primary" size="small" @click="submit">提交</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Organize from '@/api/setting/organize';
import VDistpicker from 'v-distpicker';
import Public from '@/api/public';
export default {
  name: 'institutionsEdit',
  props: {
    title: String,
    Dictionary: Object,
    type: String,
    OrgGradeTree: Array,
  },
  components: { VDistpicker },
  data() {
    return {
      show: false,
      form: {
        data: {
          name: '',
          grade_id: '',
          contact_name: '',
          contact_tel: '',
          province_code: '',
          city_code: '',
          county_code: '',
          province: '',
          city: '',
          county: '',
          pid: '',
        },
        loading: false,
        options: {
          dwjb: {
            children: [],
          },
        },
        rules: {
          name: [{ required: true, message: '请输入单位名称', trigger: 'change' }],
          grade_id: [{ required: true, message: '请选择单位级别', trigger: 'change' }],
          pid: [{ required: true, message: '请选择上级机构', trigger: 'change' }],
        },
      },
      location_error: '',
      topCompany: '',
      OrgTree: [],
    };
  },
  methods: {
    checkLocation() {
      let { province_code } = this.form.data;
      if (!province_code) {
        this.location_error = '请选择所在省';
        return false;
      }
      // if (!city_code) {
      //   this.location_error = '请选择所在市';
      //   return false;
      // }
      // if (!county_code) {
      //   this.location_error = '请选择所在区';
      //   return false;
      // }
      return true;
    },
    province(data) {
      this.form.data.province_code = data.code;
      this.form.data.province = data.value;
      let { province_code, city_code, county_code } = this.form.data;
      if (province_code && city_code && county_code) {
        this.location_error = '';
      }
    },
    city(data) {
      this.form.data.city_code = data.code;
      this.form.data.city = data.value;
      let { province_code, city_code, county_code } = this.form.data;
      if (province_code && city_code && county_code) {
        this.location_error = '';
      }
    },
    area(data) {
      this.form.data.county_code = data.code;
      this.form.data.county = data.value;
      let { province_code, city_code, county_code } = this.form.data;
      if (province_code && city_code && county_code) {
        this.location_error = '';
      }
    },
    toggle(show, data) {
      this.show = show;
      if (show && data) {
        let {
          name,
          grade_id,
          contact_name,
          contact_tel,
          province_code,
          pid,
          city_code,
          county_code,
          province,
          city,
          county,
          id,
        } = data;
        this.form.data = {
          name,
          grade_id,
          contact_name,
          contact_tel,
          province_code,
          city_code,
          county_code,
          province,
          city,
          county,
          id,
          pid,
        };
      }
      if (show && !data) {
        console.log('创建');
        this.form.data.province = '江西省';
        this.form.data.province_code = '36';
        console.log(this.form.data);
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.checkLocation()) {
            this.loading = true;
            let form = Object.assign(this.form.data);
            let { city_code, county_code } = form;
            if (!city_code) {
              form.city_code = '00';
            }
            if (!county_code) {
              form.county_code = '00';
            }

            Organize[this.type](form)
              .then(() => {
                this.$message.closeAll();
                this.$message.success(`${this.type === 'create' ? '创建' : '修改'}成功`);
                this.reset();
                this.$emit('getList');
              })
              .catch(() => {
                this.$message.closeAll();
                this.$message.error(`${this.type === 'create' ? '创建' : '修改'}失败`);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        } else {
          this.checkLocation();
          console.log('还有没有填写的内容');
          return false;
        }
      });
    },
    reset(done) {
      this.$refs.form.resetFields();
      if (done instanceof Function) {
        done();
        this.$emit('changeShow', false);
        this.location_error = '';
      } else {
        this.$emit('changeShow', false);
        this.location_error = '';
      }
    },
  },
  mounted() {},
  created() {
    Public.components({
      OrgTree: 0,
    }).then((res) => {
      this.OrgTree = res.OrgTree;
    });
  },
  computed: {},
  watch: {
    'form.data.province_code': function (n) {
      if (n) {
        n = n.toString();
        if (n.length === 6) {
          this.form.data.province_code = n.slice(0, 2);
        }
      }
    },
    'form.data.city_code': function (n) {
      if (n) {
        n = n.toString();
        if (n.length === 6) {
          this.form.data.city_code = n.slice(2, 4);
        }
      }
    },
    'form.data.county_code': function (n) {
      if (n) {
        n = n.toString();
        if (n.length === 6) {
          this.form.data.county_code = n.slice(4, 6);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.submit {
  display: flex;
  justify-content: flex-end;
}
.el-select {
  width: 100%;
}
::v-deep .distpicker-address-wrapper {
  transform: scale(0.8);
  display: flex;
  justify-content: space-between;
  width: 125%;
  transform-origin: 0 0;
  label {
    flex: 1;
    select {
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }
  label:nth-of-type(-n + 2) {
    margin-right: 10px;
  }
}
.location_error {
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: '微软雅黑';
  vertical-align: baseline;
  font-style: initial;
  outline: none;
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
::v-deep .location {
  .el-form-item__label::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}
.el-cascader {
  width: 100%;
}
</style>
