// 用户
import { tongke } from '@/axios'
class User {
  /**
   * @functionName list
   * @param { Object } params
   * @param { Number } params.page
   * @param { Number } params.size
   * @Description 用户列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/l/user', { params })
  }
  /**
   * @functionName  create
   * @param { Object }  params
   * @param { String }  params.username 用户绑定用户名
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @param { String }  params.remark 描述
   * @param { String }  params.password 密码
   * @Description 创建用户
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/user', params)
  }
  /**
   * @functionName  update
   * @param { Object }  params
   * @param { String }  params.op 用ResetPassword-重置密码|ChangePassword-修改密码
   * @param { Number }  params.status 状态，1-启用|2禁用
   * @param { String }  params.remark 描述
   * @param { String }  params.old_password 旧密码
   * @param { String }  params.password 密码
   * @Description 更新用户
   * @return { Object }
   */
  update(params) {
    return tongke.put(`/l/user/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/user/${id}`)
  }
}

export default new User()
