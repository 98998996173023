<template>
  <section class="setting-institutions-router" v-auth="'create,update,remove'">
    <div class="header">
      <div class="title">机构列表</div>
      <el-button type="primary" size="small" @click="edit('create')" v-if="auth.create">添加机构</el-button>
    </div>
    <div class="table">
      <el-table v-loading="table.loading" :data="table.data" row-key="id">
        <el-table-column prop="name" label="单位名称"></el-table-column>
        <el-table-column prop="city" label="所在地">
          <template slot-scope="scope">
            <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.county}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grade_id_a" label="单位级别"></el-table-column>
        <el-table-column prop="contact_name" label="联系人"></el-table-column>
        <el-table-column prop="contact_tel" label="联系电话"></el-table-column>
        <el-table-column prop="prop" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.grade_id !== 1 && myself.id !== scope.row.id && auth.update"
              @click="edit('update',scope.row)"
              type="text"
              size="small"
            >修改</el-button>
            <el-button
              v-if="scope.row.grade_id !== 1 && myself.id !== scope.row.id && auth.remove"
              @click="edit('remove',scope.row)"
              type="text"
              size="small"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <edit
      ref="edit"
      v-if="show"
      :title="edit_title"
      :type="type"
      :Dictionary="Dictionary"
      :OrgGradeTree="OrgGradeTree"
      @getList="getList"
      @changeShow="changeShow"
    ></edit>
  </section>
</template>

<script>
import numberToString from '@/plugin/numberToString';
import Public from '@/api/public';
import edit from './edit.vue';
import { Organize } from '@/api/permission';
export default {
  // 机构管理
  name: '',
  components: { edit },
  data() {
    return {
      table: {
        data: [],
        query: {
          page: 1,
          size: 30,
        },
        loading: false,
      },
      edit_title: '添加县市',
      Dictionary: {},
      show: false,
      OrgGradeTree: [],
      myself: '',
      auth: {
        create: true,
        update: true,
        remove: true,
      },
    };
  },
  methods: {
    changeShow(show) {
      this.show = show;
    },
    edit(type, data) {
      this.type = type;
      this.changeShow(true);
      console.log(this.type);
      switch (type) {
        case 'create': {
          this.edit_title = '添加县市';
          this.$nextTick(() => {
            this.$refs.edit.toggle(true);
          });
          break;
        }
        case 'update': {
          console.log(data);
          this.edit_title = '修改县市';
          this.$nextTick(() => {
            this.$refs.edit.toggle(true, data);
          });
          break;
        }
        case 'remove': {
          this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.table.loading = true;
              Organize.remove(data.id)
                .then(() => {
                  this.$message({
                    type: 'success',
                    message: '删除成功!',
                  });
                  return this.getList();
                })
                .finally(() => {
                  this.table.loading = false;
                });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              });
            });

          break;
        }
      }
    },
    getList() {
      this.table.loading = true;
      Organize.list(this.table.query)
        .then((res) => {
          let data = numberToString(res, 'grade_id', this.Dictionary.dwjb.children);
          this.table.data = data;
          console.log(data);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    this.myself = this.$myget('account').org;
    Public.components({ Dictionary: ['dwjb', 'OrgTree'], OrgGradeTree: 0 }).then((res) => {
      this.Dictionary = res.Dictionary;
      this.OrgGradeTree = res.OrgGradeTree;
    });
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.setting-institutions-router {
  background-color: #fff;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    .title {
      color: $h4;
      font-weight: bold;
    }
    .el-button {
      display: flex;
      // justify-content: flex-start;
    }
  }
  .table {
    flex: 1;
    width: calc(100% - 30px);
    // width: 100%;
  }
}
</style>
